/**
 * Preloader
 */

@import '../../shared/styles/common/variables';
@import '../../shared/styles/common/mixins';

.pace {
    position: fixed;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
    user-select: none;
    z-index: 9000;
    height: 4px;
    width: 180px;
    overflow: hidden;
    opacity: 1;
    transition: opacity .2s ease;
    .pace-progress {
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 100%;
        z-index: 10000;
        border-radius: 3px;
        transform: translate3d(0, 0, 0);
        background-color: $brand-primary;
        @include bg-gradient-primary;
    }
    &.pace-inactive {
        opacity: 0;
    }
}

.pace-running {
    &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $body-bg;
        z-index: 8999;
    }
}

