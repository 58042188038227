/* You can add global styles to this file, and also import other style files */

//== Vendor

@import '~bootstrap/scss/bootstrap';
@import '~ionicons/dist/css/ionicons.css';

@import '~ika.jvectormap/jquery-jvectormap-1.2.2.css';

@import '~material-colors/dist/colors.css';

@import '~loaders.css/loaders.css';

//@import '~angular2-toaster/toaster.css';

@import 'node_modules/angular2-toaster/src/toaster';
